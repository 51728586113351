import * as types from "./mutations-type"
export default {
  state: {
	 
  },
  mutations: {

  },
  actions: {

  },
  getters:{

  },
}