import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.config.productionTip = false
Vue.use(ViewUI);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import echarts from 'qs'
Vue.prototype.$echarts = echarts

import qs from 'element-ui';
Vue.use(qs);


// 封装整个项目的数据请求处理文件
import request from "./utils/request.js";
Vue.prototype.$request = request;
// 更具更字体的改变进行页面的大小改变
import flexible from "./utils/flexible.js"
flexible();
// localstorage的存取封装
import storage from "./utils/storage.js";
Vue.prototype.$storage = storage;
//获取路由带的参数
import urlKey from "./utils/urlKye.js"
Vue.prototype.$urlKey = urlKey;
//查看当前的打开的方式
import model from "./utils/model.js"
Vue.prototype.$model = model;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
