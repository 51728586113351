import axios from 'axios';
import router from '../router';
import qs from 'qs';

let baseURL = 'https://multimerchant.yinheyun.com.cn/admin.php'//正式环境
// let baseURL = '/apis/admin.php'  //测试环境
// http://xiaodianadmin.yinheyun.com.cn/api.php/Index
// http://192.168.124/api.php/Index 
function request(options) {
	// 1,创建一个axios实例
	// ElementUI.loading({
	// 	lock: true,
	// 	text: 'Loading',
	// 	spinner: 'el-icon-loading',
	// 	background: 'rgba(0, 0, 0, 0.7)'
	// })
	return new Promise((resolve, reject) => {
		const instance = axios.create({
			method: 'POST',
			baseURL,
			timeout: 9000,
			headers: {
				"Content-type": "application/x-www-form-urlencoded"
			},
			// dataType: 'json',
		});
		// 配置请求拦截
		// 请求拦截配置
		instance.interceptors.request.use(config => {
			// config.paramsSerializer = function (params) {
			// 	console.log(222);
			// 	return qs.stringify(params, { arrayFormat: 'repeat', })
			// }
			// console.log("请求之前的拦截");
			// 会对请求最通用配置
			return config;
		}, err => {
			// console.log("请求拦截失败",err);
			return err;
		})
		// 响应拦截配置
		instance.interceptors.response.use(response => {
			// console.log("响应之前的拦截");
			// response.setHeader("Access-Control-Allow-Origin", "*");
			// response.setHeader("Access-Control-Allow-Methods", "POST, GET, OPTIONS, DELETE, PUT, HEAD");
			// response.setHeader("Access-Control-Allow-Headers", "Content-Type, Token, adminID");
			// response.setHeader("Access-Control-Max-Age", "3600");
			if (response.data.code == 2) {
				alert("登录过期请重新登陆！！")
				router.push({
					path: '/login'
				})
			}
			return response.data;
		}, err => {
			// console.log("响应拦截失败",err);
			// return false;
			if (err && err.response) {
				switch (err.response.status) {
					case 400:
						err.message = "请求错误";
						break;
					case 500:
						err.message = "服务器错误"
						break;
				}
			}
			return err;
		})

		instance(options).then(res => {
			resolve(res);
		}).catch(err => {
			reject(err);
		})
	})
}
export {
	request
}
