import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


// <MenuItem name="5-1"><b @click="skip('orderList')">订单列表</b></MenuItem>
// <MenuItem name="5-2"><b @click="skip('affirmGoods')">确认收货</b></MenuItem>
// <MenuItem name="5-3"><b @click="skip('arriveRemind')">到货提醒</b></MenuItem>
// <MenuItem name="5-4"><b @click="skip('orderSetting')">订单设置</b></MenuItem>
// <MenuItem name="5-5"><b @click="skip('salesReturn')">退货申请处理</b></MenuItem>
// <MenuItem name="5-6"><b @click="skip('reimburse')">退款申请处理</b></MenuItem>
// <MenuItem name="5-7"><b @click="skip('causeReturn')">退货原因设置</b></MenuItem>
// <MenuItem name="5-8"><b @click="skip('expressWaybill')">快递单模板</b></MenuItem>
// <MenuItem name="5-9"><b @click="skip('definedPrint')">自定义打印</b></MenuItem>
// <MenuItem name="5-10"><b @click="skip('deliverPoint')">发货点信息管理</b></MenuItem>


const routes = [{
	path: '/',
	name: 'login',
	component: () => import('../views/Login/Login.vue')

},
{
	path: '/index',
	component: () => import('../views/Index/Index.vue')

},


{
	path: '/orderParticulars',
	component: () => import('../components/order/componReimburse/OrderParticulars.vue')

},

{
	path: '/orderList',
	component: () => import('../components/order/OrderList.vue')

},
{
	path: '/affirmGoods',
	component: () => import('../components/order/AffirmGoods.vue')

},
{
	path: '/arriveRemind',
	component: () => import('../components/order/ArriveRemind.vue')

},
{
	path: '/orderSetting',
	component: () => import('../components/order/OrderSetting.vue')

},
{
	path: '/salesReturn',
	component: () => import('../components/order/SalesReturn.vue')

},
{
	path: '/printList',
	component: () => import('../components/print/printList.vue')

},
{
	path: '/setPop',
	component: () => import('../components/print/setPop.vue')

},
{
	path: '/reimburse',
	component: () => import('../components/order/Reimburse.vue')

},
{
	path: '/causeReturn',
	component: () => import('../components/order/CauseReturn.vue')

},
{
	path: '/expressWaybill',
	component: () => import('../components/order/ExpressWaybill.vue')

},
{
	path: '/definedPrint',
	component: () => import('../components/order/DefinedPrint.vue')

},
{
	path: '/deliverPoint',
	component: () => import('../components/order/DeliverPoint.vue')

},




{
	path: '/maintain',
	name: 'Maintain',
	component: () => import('../components/maintain/Maintain.vue')
},
{
	path: '/role',
	name: 'Role',
	component: () => import('../components/maintain/Role.vue')
},
{
	path: '/addUser',
	name: 'addUser',
	component: () => import('../components/maintain/AddUser.vue')
},
{
	path: '/jurisdiction',
	name: 'jurisdiction',
	component: () => import('../components/maintain/Jurisdiction.vue')
},
{
	path: '/login',
	component: () => import('../views/Login/Login.vue'),
},
{
	path: '/addshop',
	component: () => import('../components/Shop/addShop.vue')
},
{
	path: '/addList',
	component: () => import('../components/Shop/AddList.vue')
},
{
	path: '/industry',
	component: () => import('../components/Shop/Industry.vue')
},
{
	path: '/categories',
	component: () => import('../components/Shop/Categories.vue')
},
{
	path: '/brand',
	component: () => import('../components/Shop/Brand.vue')
},
{
	path: '/addBrand',
	component: () => import('../components/Shop/AddBrand.vue')
},
{
	path: '/commodityList',
	component: () => import('../components/commodity/List.vue'),
},
{
	path: '/addCommodity',
	component: () => import('../components/commodity/AddCommodity.vue')
},
{
	path: '/commodityAudit',
	component: () => import('../components/commodity/Audit.vue')
},
{
	path: '/commodityRecycle',
	component: () => import('../components/commodity/Recycle.vue')
},
{
	path: '/commodityAlter',
	component: () => import('../components/commodity/Alter.vue')
},
{
	path: '/commodityComment',
	component: () => import('../components/commodity/Comment.vue')
},
{
	path: '/commodityClassify',
	component: () => import('../components/commodity/Classify.vue')
},
{
	path: '/commodityType',
	component: () => import('../components/commodity/Type.vue')
},
{
	path: '/commoditySign',
	component: () => import('../components/commodity/Sign.vue')
},
{
	path: '/commodityMeasure',
	component: () => import('../components/commodity/Measure.vue')
},
{
	path: '/commodityPicture',
	component: () => import('../components/commodity/Picture.vue')
},
{
	path: '/addClassify',
	component: () => import('../components/commodity/componClass/AddClassify.vue')
},
{
	path: '/member',
	name: 'Member',
	component: () => import('../components/member/Member.vue')
},
{
	path: '/manlabel',
	name: 'Manlabel',
	component: () => import('../components/member/Manlabel.vue')
},
{
	path: '/manrank',
	name: 'Manrank',
	component: () => import('../components/member/Manrank.vue')
},
{
	path: '/memberdetail',
	name: 'Memberdetail',
	component: () => import('../components/member/Memberdetail.vue')
},
{
	path: '/shopset',
	name: 'Shopset',
	component: () => import('../components/shopnum/Shopset.vue')
},
{
	path: '/shopget',
	name: 'Shopget',
	component: () => import('../components/shopnum/Shopget.vue')
},
{
	path: '/setretail',
	name: 'Setretail',
	component: () => import('../components/retail/Setretail.vue')
},
{
	path: '/retailist',
	name: 'RetailList',
	component: () => import('../components/retail/RetailList.vue')
},
{
	path: '/retailrank',
	name: 'Retailrank',
	component: () => import('../components/retail/Retailrank.vue')
},
{
	path: '/activityList',
	component: () =>
		import('../components/promotion/secondKill/activityList.vue'),
	children: []
},
{
	path: '/setGoods',
	name: 'setGoods',
	component: () =>

		import('../components/promotion/secondKill/setGoods.vue')
},
{
	path: '/addCouponInfo',
	name: '/addCouponInfo',
	component: () =>
		import('../components/promotion/coupon/addCouponInfo.vue'),
},
{
	path: '/groupList',
	name: '/groupList',
	component: () =>
		import('../components/promotion/group/groupList.vue'),
},
{
	path: '/couponList',
	name: '/couponList',
	component: () =>
		import('../components/promotion/coupon/couponList.vue'),
},
{
	path: '/addAdvertising',
	name: '/addAdvertising',
	component: () =>
		import('../components/operate/advertising/addAdvertising.vue'),
},
{
	path: '/advertisingList',
	name: '/advertisingList',
	component: () =>
		import('../components/operate/advertising/advertisingList.vue'),
},
{
	path: '/latformInfo',
	name: '/latformInfo',
	component: () =>
		import('../components/setUp/platformSettings/latformInfo.vue'),
},
{
	path: '/latformInfo',
	name: '/latformInfo',
	component: () =>
		import('../components/setUp/platformSettings/latformInfo.vue'),
},
{
	path: '/freight',
	name: '/freight',
	component: () =>
		import('../components/setUp/pay_distribution/freight.vue'),
},
{
	path: '/addfreight',
	name: '/addfreight',
	component: () =>
		import('../components/setUp/pay_distribution/addfreight.vue'),
},
{
	path: '/logistics',
	name: '/logistics',
	component: () =>
		import('../components/setUp/pay_distribution/logistics.vue'),
},
{
	path: '/configure',
	name: '/configure',
	component: () =>
		import('../components/setUp/parameter/configure.vue'),
},
{
	path: '/liftingPointList',
	name: '/liftingPointList',
	component: () =>
		import('../components/setUp/pay_distribution/liftingPoint/liftingPointList.vue'),
},
{
	path: '/addLiftingPoint',
	name: '/addLiftingPoint',
	component: () =>
		import('../components/setUp/pay_distribution/liftingPoint/addLiftingPoint.vue'),
},
{
	path: '/expressSel',
	name: '/expressSel',
	component: () =>
		import('../components/express/expressSel.vue'),
},
{
	path: '/expressInfo',
	name: '/expressInfo',
	component: () =>
		import('../components/express/expressInfo.vue'),
},
{
	path: '/addequity',
	name: '/addequity',
	component: () =>
		import('../components/equity/addequity.vue'),
},
{
	path: '/equitylist',
	name: '/equitylist',
	component: () =>
		import('../components/equity/equitylist.vue'),
},
{
	path: '/examine',
	name: '/examine',
	component: () =>
		import('../components/equity/examine.vue'),
},
{
	path: '/equityorder',
	name: '/equityorder',
	component: () =>
		import('../components/equity/equityorder.vue'),
},
]

const router = new VueRouter({
	// mode: 'history',
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
