import {
	request,
	jsonp
} from "./network.js";
// import {qs} from "qs";
// {/* <script src="https://cdn.bootcss.com/qs/6.5.1/qs.min.js"></script> */}
var qs = require("qs");

// 反序列化
let data = "name=Tom&age=18";
let obj = qs.parse(data)
console.log(obj)
// { name: 'Tom', age: '18' }

// 序列化
let str = qs.stringify(obj);
console.log(str);
// name=Tom&age=18



// http://xiaodianadmin.yinheyun.com.cn/admin.php/api.php/Index
// 注册时验证手机号是否存在的
function Login(obj) { //登录
	return request({
		url: "Login",
		params: {
			admin_name: obj.name,
			admin_pwd: obj.pass,
			ip: obj.ip,
			// area:'1',
			browser: obj.brow
		}
	})
}
function selRoleList(obj) { //登录
	return request({
		url: "Index/selRoleList",
		params: {
			token: obj.token,
		}
	})
}

function Index(obj) { //首页列表
	return request({
		url: "Index/Test",
		params: {
			token: obj.token,
		}
	})
}

function roleList(obj) { //权限人员列表
	return request({
		url: "role/roleList",
		params: {
			token: obj.token,
			page: 1,
			limit: 10
		}
	})
}

function roleDel(obj) { //删除权限
	return request({
		url: "role/roleDel",
		params: {
			token: obj.token,
			role_id: obj.id
		}
	})
}

function roleUpIsStart(obj) { //
	return request({
		url: "role/roleUpIsStart",
		params: {
			token: obj.token,
			role_id: obj.id,
			is_start: obj.start
		}
	})
}

function roleAddName(obj) { //权限人物添加
	return request({
		url: "role/roleAddName",
		params: {
			token: obj.token,
			role_name: obj.name,
			content: obj.content
		}
	})
}

function roleUpInfo(obj) { //人物信息编辑
	return request({
		url: "role/roleUpInfo",
		params: {
			token: obj.token,
			role_name: obj.name,
			content: obj.content,
			role_id: obj.id
		}
	})
}

function roleAddList(obj) { //获取权限列表
	return request({
		url: "role/roleAddList",
		params: {
			token: obj.token,
			role_id: obj.id
		}
	})
}

function roleAdd(obj) { //人物添加权限
	console.log(obj)
	return request({
		url: "role/roleAdd",
		params: {
			token: obj.token,
			role_id: obj.id,
			node_id: obj.filsid,
			pnode_id: obj.fatherid
		}
	})
}

function adminSel(obj) { //管理员列表
	return request({
		url: "admin/adminSel",
		params: {
			token: obj.token,
			admin_user: obj.user,
			page: obj.page,
			limit: obj.limit
		}
	})
}

function selRoleData(obj) { //人员列表
	return request({
		url: "admin/selRoleData",
		params: {
			token: obj.token
		}
	})
}

function addAdmin(obj) { //添加人员
	return request({
		url: "admin/addAdmin",
		params: {
			token: obj.token,
			admin_name: obj.name,
			admin_user: obj.user,
			admin_email: obj.email,
			admin_pwd: obj.pass,
			admin_pwds: obj.checkPass,
			admin_tel: obj.tel,
			remarks: obj.remarks,
			role_id: obj.role_id
		}
	})
}

function updateAdmin(obj) { //修改人员信息
	return request({
		url: "admin/updateAdmin",
		params: {
			token: obj.token,
			admin_name: obj.name,
			admin_user: obj.user,
			admin_email: obj.email,
			admin_pwd: obj.pass,
			admin_pwds: obj.checkPass,
			admin_tel: obj.tel,
			old_pwd: obj.oldpass,
			remarks: obj.remarks,
			role_id: obj.role_id,
			admin_id: obj.admin_id
		}
	})
}

function updateAdminSel(obj) { //添加人员
	return request({
		url: "admin/updateAdminSel",
		params: {
			token: obj.token,
			admin_id: obj.id
		}
	})
}

function adminUpIsStart(obj) { //是否开启
	return request({
		url: "admin/adminUpIsStart",
		params: {
			token: obj.token,
			admin_id: obj.id,
			is_start: obj.start
		}
	})
}

function delAdmin(obj) { //删除人员
	return request({
		url: "admin/delAdmin",
		params: {
			token: obj.token,
			admin_id: obj.id
		}
	})
}
function IndexDataOverview(obj) { //删除人员
	return request({
		url: "Index/IndexDataOverview",
		params: {
			token: obj.token,
			login_type: obj.login_type
		}
	})
}

function selIndustryData(obj) { //行业管理
	return request({
		url: "Industry/selIndustryData",
		params: {
			token: obj.token,
			i_name: obj.i_name,
			limit: obj.limit,
			page: obj.page
		}
	})
}

function delIndustry(obj) { //行业管理删除
	return request({
		url: "Industry/delIndustry",
		params: {
			token: obj.token,
			i_id: obj.id
		}
	})
}

function selCategoryData(obj) { //主因列表
	return request({
		url: "Category/selCategoryData",
		params: {
			token: obj.token,
			limit: obj.limit,
			page: obj.page
		}
	})
}

function brandList(obj) { //品牌列表
	return request({
		url: "brand/brandList",
		params: {
			token: obj.token,
			limit: obj.limit,
			b_name: obj.b_name,
			page: obj.page
		}
	})
}
function popList(obj) { //品牌列表
	return request({
		url: "Pop/popList",
		params: {
			token: obj.token,
			admin_type: obj.admin_type,
			store_id: obj.store_id,
			page: obj.page,
			limit: obj.limit
		}
	})
}
function setPop(obj) { //品牌列表
	return request({
		url: "Pop/setPop",
		params: {
			token: obj.token,
			admin_type: obj.admin_type,
			store_id: obj.store_id,
			title: obj.title,
			content: obj.content,
			pop_id: obj.pop_id,
		}
	})
}
function deletePop(obj) { //品牌列表
	return request({
		url: "Pop/deletePop",
		params: {
			token: obj.token,
			pop_id: obj.pop_id,
		}
	})
}

function storeSelectData(obj) { //品牌列表
	return request({
		url: "Store/storeSelectData",
		params: {
			token: obj.token
		}
	})
}

function addStoreData(obj) { //添加店铺
	return request({
		url: "Store/addStoreData",
		params: {
			token: obj.token,
			login_name: obj.name,
			login_pwd: obj.passwd,
			login_pwds: obj.passwdCheck,
			s_logo: obj.s_logo,
			s_name: obj.shop_name,
			b_id: obj.brand,
			i_id: obj.industry,
			c_id: obj.category,
			s_address: obj.site,
			user: obj.linkman,
			tel: obj.phone,
			s_content: obj.introduce,
			is_take: obj.invite,
			start_time: obj.start_date,
			end_time: obj.end_date,
			is_judge: obj.audit,
			su_name: obj.su_name,
			su_id: obj.su_id
		}
	})
}

function storeList(obj) { //店铺列表
	return request({
		url: "Store/storeList",
		params: {
			token: obj.token,
			s_name: obj.s_name,
			page: obj.page,
			limit: obj.limit,
			level: obj.value
		}
	})
}
function subbranchSelData(obj) { //支行列表
	return request({
		url: "Store/subbranchSelData",
		params: {
			token: obj.token,
		}
	})
}

function logout(obj) { //注销
	return request({
		url: "Login/logout",
		params: {
			token: obj.token
		}
	})
}

function delStoreData(obj) { //店铺删除
	return request({
		url: "Store/delStoreData",
		params: {
			token: obj.token,
			storeid: obj.id
		}
	})
}

function addIndustry(obj) { //行业添加
	return request({
		url: "Industry/addIndustry",
		params: {
			token: obj.token,
			i_name: obj.name,
			i_order: obj.ranking
		}
	})
}

function upIndustry(obj) { //行业修改
	return request({
		url: "Industry/upIndustry",
		params: {
			token: obj.token,
			i_name: obj.name,
			i_order: obj.ranking,
			i_id: obj.id
		}
	})
}

function selIndustryList(obj) { //类目下拉
	return request({
		url: "Industry/selIndustryList",
		params: {
			token: obj.token
		}
	})
}

function addCategory(obj) { //添加类目
	return request({
		url: "Category/addCategory",
		params: {
			token: obj.token,
			c_name: obj.name,
			i_id: obj.id
		}
	})
}

function delCategory(obj) { //删除类目
	return request({
		url: "Category/delCategory",
		params: {
			token: obj.token,
			c_id: obj.id
		}
	})
}

function upCategory(obj) { //类目编辑
	return request({
		url: "Category/upCategory",
		params: {
			token: obj.token,
			i_id: obj.i_id,
			c_name: obj.name,
			c_id: obj.c_id
		}
	})
}

function upIsShow(obj) { //类目编辑
	return request({
		url: "Brand/upIsShow",
		params: {
			token: obj.token,
			b_id: obj.id,
			b_show: obj.show
		}
	})
}

function brandDel(obj) { //品牌删除
	return request({
		url: "Brand/brandDel",
		params: {
			token: obj.token,
			b_id: obj.id
		}
	})
}

function brandAdd(obj) { //品牌添加
	return request({
		url: "Brand/brandAdd",
		params: {
			token: obj.token,
			b_name: obj.name,
			b_Initials: obj.initials,
			b_logo: obj.logo,
			b_content: obj.content,
			b_show: obj.show,
			b_order: obj.order
		}
	})
}

function brandOneData(obj) { //品牌查询
	return request({
		url: "Brand/brandOneData",
		params: {
			token: obj.token,
			b_id: obj.id
		}
	})
}

function brandUp(obj) { //品牌修改
	return request({
		url: "Brand/brandUp",
		params: {
			token: obj.token,
			b_name: obj.name,
			b_Initials: obj.initials,
			b_logo: obj.logo,
			b_content: obj.content,
			b_show: obj.show,
			b_order: obj.order,
			b_id: obj.id
		}
	})
}

function storeOneData(obj) { //店铺查询
	return request({
		url: "store/storeOneData",
		params: {
			token: obj.token,
			storeid: obj.id
		}
	})
}

function upStoreData(obj) { //店铺查询
	return request({
		url: "store/upStoreData",
		params: {
			token: obj.token,
			login_name: obj.name,
			login_pwd: obj.passwd,
			login_pwds: obj.passwdCheck,
			s_logo: obj.s_logo,
			s_name: obj.shop_name,
			b_id: obj.brand,
			i_id: obj.industry,
			c_id: obj.category,
			s_address: obj.site,
			user: obj.linkman,
			tel: obj.phone,
			s_content: obj.introduce,
			is_take: obj.invite,
			start_time: obj.start_date,
			end_time: obj.end_date,
			is_judge: obj.audit,
			storeid: obj.id,
			su_name: obj.su_name,
			su_id: obj.su_id
		}
	})
}

function goodsQueryList(obj) { //商品列表
	return request({
		url: "goods/goodsQueryList",
		params: {
			token: obj.token,
			gname: obj.gname,
			storeid: obj.storeid,
			pstoreid: obj.pstoreid,
			page: obj.page,
			limit: obj.limit,
			status: obj.status
		}
	})
}

function selGoodsStoreName(obj) { //店铺列表
	return request({
		url: "goods/selGoodsStoreName",
		params: {
			token: obj.token,
			type: obj.type,
			pstoreid: obj.pstoreid
		}
	})
}

function delGoodsData(obj) { //商品删除
	return request({
		url: "goods/delGoodsData",
		params: {
			token: obj.token,
			gid: obj.id
		}
	})
}

function goodsIsGrounding(obj) { //商品上架
	return request({
		url: "goods/goodsIsGrounding",
		params: {
			token: obj.token,
			gid: obj.id,
			is_grounding: obj.grounding
		}
	})
}

function addTypeList(obj) { //上级列表
	return request({
		url: "Goodstype/addTypeList",
		params: {
			token: obj.token,
			pstoreid: obj.pstoreid,
			login_type: obj.login_type
		}
	})
}

function getStoreDataList(obj) { //上级列表
	return request({
		url: "Store/getStoreDataList",
		params: {
			token: obj.token,
			pstoreid: obj.pstoreid,
			login_type: obj.login_type
		}
	})
}

function typeAddData(obj) { //添加分类
	return request({
		url: "Goodstype/typeAddData",
		params: {
			token: obj.token,
			t_name: obj.name,
			pid: obj.id,
			is_display: obj.display,
			tcontent: obj.tcontent,
			tlogo: obj.tlogo,
			pstoreid: obj.pstoreid,
			login_type: obj.login_type,
			type_status: obj.type_status,
		}
	})
}

function typeListData(obj) { //分类列表
	return request({
		url: "Goodstype/typeListData",
		params: {
			token: obj.token,
			storeid: obj.storeid
		}
	})
}

function getMerchantData(obj) { //获取商户列表
	return request({
		url: "Store/getMerchantData",
		params: {
			token: obj.token,
			// storeid:obj.storeid
		}
	})
}

function delTypeData(obj) { //分类删除
	return request({
		url: "Goodstype/delTypeData",
		params: {
			token: obj.token,
			gtid: obj.id
		}
	})
}

function typeOneData(obj) { //分类查询
	return request({
		url: "Goodstype/typeOneData",
		params: {
			token: obj.token,
			gtid: obj.id
		}
	})
}

function upTypeData(obj) { //分类修改
	return request({
		url: "Goodstype/upTypeData",
		params: {
			token: obj.token,
			t_name: obj.name,
			pid: obj.pid,
			is_display: obj.display,
			tcontent: obj.tcontent,
			tlogo: obj.tlogo,
			gtid: obj.id,
			pstoreid: obj.pstoreid,
			login_type: obj.login_type,
			type_status: obj.type_status
		}
	})
}

function upLevelStatus(obj) { //分类修改
	return request({
		url: "Goodstype/upLevelStatus",
		params: {
			token: obj.token,
			gtid: obj.id
		}
	})
}

function selGoodsClassData(obj) { //类型列表
	return request({
		url: "Goodsclass/selGoodsClassData",
		params: {
			token: obj.token,
			page: obj.page,
			limit: obj.limit,
			login_type: obj.type,
			storeid: obj.storeid
		}
	})
}

function goodsClassAdd(obj) { //类型列表添加
	return request({
		url: "Goodsclass/goodsClassAdd",
		params: {
			token: obj.token,
			gcname: obj.name,
			login_type: obj.type,
			storeid: obj.id
		}
	})
}

function goodsClassUp(obj) { //类型列表编辑
	return request({
		url: "Goodsclass/goodsClassUp",
		params: {
			token: obj.token,
			gcname: obj.name,
			gcid: obj.id,
			storeid: obj.storeid,
			login_type: obj.type
		}
	})
}

function delGoodsClassData(obj) { //类型列表删除
	return request({
		url: "Goodsclass/delGoodsClassData",
		params: {
			token: obj.token,
			gcname: obj.name,
			gcid: obj.id
		}
	})
}

function getNextSelectList(obj) { //店铺列表
	return request({
		url: "Goodsclass/getNextSelectList",
		params: {
			token: obj.token
		}
	})
}


function selGoodsSpecList(obj) { //列表规格
	return request({
		url: "Goodsclass/selGoodsSpecList",
		params: {
			token: obj.token,
			gcid: obj.id
		}
	})
}

function addGoodsSpec(obj) { //列表规格
	return request({
		url: "Goodsclass/addGoodsSpec",
		params: {
			token: obj.token,
			login_type: obj.type,
			gcid: obj.id,
			specval: obj.specval,
			is_checked: obj.checked,
			specname: obj.name,
			storeid: '8'
		}
	})
}

function delGoodsSpecData(obj) { //规格删除
	return request({
		url: "Goodsclass/delGoodsSpecData",
		params: {
			token: obj.token,
			gsid: obj.id,
		}
	})
}

function selGoodsSpecInfo(obj) { //规格编辑
	return request({
		url: "Goodsclass/selGoodsSpecInfo",
		params: {
			token: obj.token,
			gsid: obj.id,
		}
	})
}

function upGoodsSpecData(obj) { //规格编辑
	return request({
		url: "Goodsclass/upGoodsSpecData",
		params: {
			token: obj.token,
			gsid: obj.gsid,
			gcid: obj.gcid,
			specval: obj.specval,
			is_checked: obj.checked,
			specname: obj.name
		}
	})
}

function labelAddData(obj) { //标签列表添加
	return request({
		url: "Goodslabel/labelAddData",
		params: {
			token: obj.token,
			glname: obj.name,
			glimg: obj.img,
			storeid: obj.storeid,
			login_type: obj.type
		}
	})
}

function labelListData(obj) { //标签列表
	return request({
		url: "Goodslabel/labelListData",
		params: {
			token: obj.token,
			login_type: obj.type,
			storeid: obj.storeid
		}
	})
}

function delLabelData(obj) { //标签列表删除
	return request({
		url: "Goodslabel/delLabelData",
		params: {
			token: obj.token,
			glid: obj.id
		}
	})
}

function upLabelData(obj) { //标签列表编辑
	return request({
		url: "Goodslabel/upLabelData",
		params: {
			token: obj.token,
			glid: obj.glid,
			glimg: obj.img,
			glname: obj.name
		}
	})
}

function meteringAddData(obj) { //计量单位添加
	return request({
		url: "Goodsmetering/meteringAddData",
		params: {
			token: obj.token,
			gmname: obj.name,
			gmtype: obj.show,
			storeid: obj.id,
			login_type: obj.type,
			gmcode: obj.gmcode
		}
	})
}

function closeOrder(obj) { //计量单位添加
	return request({
		url: "Shorder/closeOrder",
		params: {
			token: obj.token,
			order_id: obj.order_id
		}
	})
}
function meteringListData(obj) { //计量单位列表
	return request({
		url: "Goodsmetering/meteringListData",
		params: {
			token: obj.token,
			login_type: obj.id,
			storeid: obj.storeid,
			page: obj.page,
			limit: obj.limit
		}
	})
}

function upmeteringType(obj) { //计量单位列表默认
	return request({
		url: "Goodsmetering/upmeteringType",
		params: {
			token: obj.token,
			gmid: obj.id,
			gmtype: obj.gmtype
		}
	})
}

function delmeteringData(obj) { //计量单位列表删除
	return request({
		url: "Goodsmetering/delmeteringData",
		params: {
			token: obj.token,
			gmid: obj.id
		}
	})
}

function upmeteringData(obj) { //计量单位列表编辑
	return request({
		url: "Goodsmetering/upmeteringData",
		params: {
			token: obj.token,
			gmname: obj.name,
			gmid: obj.id,
			gmtype: obj.type,
			gmcode: obj.gmcode,
		}
	})
}

function selGoodsTypeData(obj) { //标签
	return request({
		url: "Goods/selGoodsTypeData",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid
		}
	})
}

function GoodsselGoodsClassData(obj) { //类型
	return request({
		url: "Goods/selGoodsClassData",
		params: {
			token: obj.token,
			login_type: obj.type
		}
	})
}

function selGoodsSpecData(obj) { //类型下的规格
	return request({
		url: "Goods/selGoodsSpecData",
		params: {
			token: obj.token,
			gcid: obj.gcid
		}
	})
}

function selGoodsReviewData(obj) { //商品审核列表
	return request({
		url: "Goods/selGoodsReviewData",
		params: {
			token: obj.token,
			login_type: obj.type,
			page: obj.page,
			limit: obj.limit
		}
	})
}

function goodsAddData(obj) { //商品添加
	return request({
		headers: {
			"Content-type": "application/json",
			// "Content-type": "application/x-www-form-urlencoded",
		},
		url: "Goods/goodsAddData",
		data: {
			token: obj.token,
			login_type: obj.type,
			gtid: obj.gtid,
			gname: obj.gname,
			gtitle: obj.gtitle,
			storeid: obj.storeid,
			glid: obj.glid,
			t_id: obj.t_id,
			selling_price: obj.selling_price,
			original_price: obj.original_price,
			stock: obj.stock,
			s_warning_val: obj.s_warning_val,
			m_id: obj.m_id,
			g_weight: obj.g_weight,
			is_grounding: obj.is_grounding,
			gimg: obj.gimg,
			gcid: obj.gcid,
			gcval: obj.gcval,
			gservice: obj.gservice,
		},
		// paramsSerializer: params => {
		// 	return qs.stringify(params, {
		// 	  indices: false
		// 	})
		//   }
		// paramsSerializer: function (params) {
		// 	return qs.stringify(params, { arrayFormat: 'repeat' })
		// }
	})
}

function selGoodsRecycleData(obj) { //回收站列表
	return request({
		url: "Goods/selGoodsRecycleData",
		params: {
			token: obj.token,
			login_type: obj.type
		}
	})
}

function upGoodsReviewData(obj) { //商品审核
	return request({
		url: "Goods/upGoodsReviewData",
		params: {
			token: obj.token,
			gid: obj.gid,
			content: obj.content,
			status: obj.status
		}
	})
}


function upGoodsRecycleData(obj) { //商品回收站删除/恢复
	return request({
		url: "Goods/upGoodsRecycleData",
		params: {
			token: obj.token,
			gid: obj.id,
			status: obj.status
		}
	})
}

function setGoodsReviewType(obj) { //商品回收站删除/恢复
	return request({
		url: "Goods/setGoodsReviewType",
		params: {
			token: obj.token,
			gid: obj.id,
		}
	})
}

function selGoodsReviewDataList(obj) { //商品回收站删除/恢复
	return request({
		url: "Goods/selGoodsReviewDataList",
		params: {
			token: obj.token,
			gid: obj.id,
		}
	})
}

function goodsUpInfoData(obj) { //商品修改查询
	return request({
		url: "Goods/goodsUpInfoData",
		params: {
			token: obj.token,
			gid: obj.id,
		}
	})
}

function selOrderList(obj) { //商品修改查询
	return request({
		url: "Shorder/selOrderList",
		params: {
			token: obj.token,
			order: obj.order,
			userName: obj.name,
			storeid: obj.storeid,
			limit: obj.limit,
			page: obj.page,
			status: obj.status,
			time: obj.time,
			login_type: obj.type,
			su_id: obj.su_id
		}
	})
}

function goodsUpData(obj) { //商品修改
	return request({
		url: "Goods/goodsUpData",
		params: {
			token: obj.token,
			login_type: obj.type,
			gtid: obj.gtid,
			gname: obj.gname,
			gtitle: obj.gtitle,
			storeid: obj.storeid,
			glid: obj.glid,
			t_id: obj.t_id,
			selling_price: obj.selling_price,
			original_price: obj.original_price,
			stock: obj.stock,
			s_warning_val: obj.s_warning_val,
			m_id: obj.m_id,
			g_weight: obj.g_weight,
			is_grounding: obj.is_grounding,
			gimg: obj.gimg,
			gcid: obj.gcid,
			gcval: obj.gcval,
			gservice: obj.gservice,
			gid: obj.gid
		}
	})
}

function receivingList(obj) { //确认收货
	return request({
		url: "Shorder/receivingList",
		params: {
			token: obj.token,
			order: obj.order,
			userName: obj.name,
			page: obj.page,
			limit: obj.limit,
			login_type: obj.type,
			time: obj.time
		}
	})
}

function receivingSet(obj) { //确认商品收货
	return request({
		url: "Shorder/receivingSet",
		params: {
			token: obj.token,
			orderid: obj.id
		}
	})
}

function orderSetList(obj) { //订单设置列表
	return request({
		url: "Shorder/orderSetList",
		params: {
			token: obj.token,
			login_type: obj.type
		}
	})
}

function orderSetUp(obj) { //订单设置修改
	return request({
		url: "Shorder/orderSetUp",
		params: {
			token: obj.token,
			login_type: obj.type,
			storeid: obj.storeid,
			ordersId: obj.ordersId,
			order_seckill_time: obj.seckill,
			order_time: obj.normal,
			order_complete: obj.deliver,
			order_end: obj.accomplish,
			// order_praise: obj.evaluate
		}
	})
}

function refundOrderList(obj) { //退款订单列表
	return request({
		url: "Shorder/refundOrderList",
		params: {
			token: obj.token,
			status: obj.status,
			order_sn: obj.order_sn,
			time: obj.time,
			page: obj.page,
			limit: obj.limit,
			login_type: obj.type
		}
	})
}

function refundOrderInfo(obj) { //退款订单详情
	return request({
		url: "Shorder/refundOrderInfo",
		params: {
			token: obj.token,
			orderid: obj.id
		}
	})
}

function refundOrderSet(obj) { //退款
	return request({
		url: "Shorder/refundOrderSet",
		params: {
			token: obj.token,
			orderid: obj.id,
			handle_content: obj.content,
			status: obj.status
		}
	})
}

function orderRefundGoodsSel(obj) { //退款原因列表
	return request({
		url: "Shorder/orderRefundGoodsSel",
		params: {
			token: obj.token,
			login_type: obj.type,
			page: obj.page,
			limit: obj.limit
		}
	})
}

function orderRefundGoodsSet(obj) { //退款原因修改/添加
	return request({
		url: "Shorder/orderRefundGoodsSet",
		params: {
			token: obj.token,
			login_type: obj.type,
			storeid: obj.storeid,
			rname: obj.rname,
			is_start: obj.is_start,
			orid: obj.orid
		}
	})
}


function memberLabelList(obj) { //标签列表
	return request({
		url: "MemberLabel/memberLabelList",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid,
			page: obj.page,
			limit: obj.limit
		}
	})
}

function memberLabelAdd(obj) { //添加标签
	return request({
		url: "MemberLabel/memberLabelAdd",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			// ml_id:obj.ml_id,
			storeid: obj.storeid,
			name: obj.name,
			count: obj.count,
			money: obj.money
		}
	})
}

function memberLabelUp(obj) { //添加标签
	return request({
		url: "MemberLabel/memberLabelUp",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			ml_id: obj.ml_id,
			storeid: obj.storeid,
			name: obj.name,
			count: obj.count,
			money: obj.money
		}
	})
}

function memberLabelDel(obj) { //添加标签
	return request({
		url: "MemberLabel/memberLabelDel",
		params: {
			token: obj.token,
			ml_id: obj.ml_id
		}
	})
}

function memberGradeList(obj) { //会员等级列表
	return request({
		url: "MemberGrade/memberGradeList",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid,
			page: obj.page,
			limit: obj.limit
		}
	})
}

function memberGradeAdd(obj) { //添加会员等级
	return request({
		url: "MemberGrade/memberGradeAdd",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid,
			g_name: obj.g_name,
			g_val: obj.g_val,
			is_checkde: obj.is_checkde,
			freight: obj.freight,
			comment: obj.comment
		}
	})
}

function memberGradeUp(obj) { //添加会员等级
	return request({
		url: "MemberGrade/memberGradeUp",
		params: {
			token: obj.token,
			mg_id: obj.mg_id,
			login_type: obj.login_type,
			storeid: obj.storeid,
			g_name: obj.g_name,
			g_val: obj.g_val,
			is_checkde: obj.is_checkde,
			freight: obj.freight,
			comment: obj.comment
		}
	})
}

function memberGradeDel(obj) { //删除会员等级标签
	return request({
		url: "MemberGrade/memberGradeDel",
		params: {
			token: obj.token,
			mg_id: obj.mg_id
		}
	})
}

function memberGradeCheckdeUp(obj) { //列表切换状态
	return request({
		url: "MemberGrade/memberGradeCheckdeUp",
		params: {
			token: obj.token,
			mg_id: obj.mg_id
		}
	})
}

function memberList(obj) { //会员等级列表
	return request({
		url: "Member/memberList",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid,
			page: obj.page,
			limit: obj.limit,
			tel: obj.tel,
			user_name: obj.user_name,
			rdate: obj.rdate
		}
	})
}

function orderRefundGoodsDel(obj) { //退款原因删除
	return request({
		url: "Shorder/orderRefundGoodsDel",
		params: {
			token: obj.token,
			orid: obj.orid
		}
	})
}

function memberInfo(obj) { //会员详情
	return request({
		url: "Member/memberInfo",
		params: {
			token: obj.token,
			m_id: obj.m_id
		}
	})
}

function goodsStockList(obj) { //入库列表
	return request({
		url: "GoodsStock/goodsStockList",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid,
			page: obj.page,
			limit: obj.limit,
			name: obj.name,
			type: obj.type,
			time: obj.time,
			is_type: obj.is_type
		}
	})
}

function memberDel(obj) { //删除会员
	return request({
		url: "Member/memberDel",
		params: {
			token: obj.token,
			m_id: obj.m_id
		}
	})
}

function distributionSel(obj) { //分销基础设置查询
	return request({
		url: "DistributionGrade/distributionSel",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid
		}
	})
}

function distributionSet(obj) { //分销基础设置
	return request({
		url: "DistributionGrade/distributionSet",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid,
			min_fee: obj.min_fee,
			d_grade: obj.d_grade,
			grade_where: obj.grade_where,
			grade_where_val: obj.grade_where_val,
			o_level: obj.o_level,
			t_level: obj.t_level,
			th_level: obj.th_level
		}
	})
}

function disGradeSel(obj) { //分销等级列表
	return request({
		url: "DistributionGrade/disGradeSel",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid
		}
	})
}

function disGradeSet(obj) { //分销等级添加
	return request({
		url: "DistributionGrade/disGradeSet",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid,
			dg_id: obj.dg_id,
			gd_name: obj.gd_name,
			o_level: obj.o_level,
			t_level: obj.t_level,
			th_level: obj.th_level,
			data: obj.data
		}
	})
}

function getUpStoreData(obj) { //查询门店上级商户id
	return request({
		url: "Store/getUpStoreData",
		params: {
			token: obj.token,
			// login_type: obj.login_type,
			storeid: obj.storeid
		}
	})
}

function businessList(obj) { //商户列表
	return request({
		url: "Promotion/businessList",
		params: {
			/* token: obj.token,
			type: obj.type,
			status: obj.status,
			admin_type: obj.admin_type, */
		}
	})
}

function businessStoreList(obj) { //门店列表
	return request({
		url: "Promotion/storeList",
		params: {
			business_id: obj.business_id,
			token: obj.token,
			admin_type: obj.admin_type,
		}
	})
}

function addActive(obj) { //添加活动
	return request({
		url: "Promotion/setActive",
		params: {
			token: obj.token,
			admin_type: obj.admin_type,
			business_id: obj.business_id,
			active_title: obj.active_title,
			start_time: obj.start_time,
			end_time: obj.end_time,
			is_online: obj.is_online,
			type: obj.type,
			active_id: obj.active_id

		}
	})
}

function changShelf(obj) { //修改上下架
	return request({
		url: "Promotion/setActive",
		params: {
			token: obj.token,
			active_id: obj.active_id,
			admin_type: obj.admin_type,
			business_id: obj.business_id,
			active_title: obj.active_title,
			start_time: obj.start_time,
			end_time: obj.end_time,
			is_online: obj.is_online,
			type: obj.type,
		}
	})
}

function delActive(obj) { //删除活动
	return request({
		url: "Promotion/deleteActive",
		params: {
			token: obj.token,
			active_id: obj.active_id,
		}
	})
}

function activeList(obj) { //活动列表
	return request({
		url: "Promotion/activeList",
		params: {
			token: obj.token,
			type: obj.type,
			status: obj.status,
			admin_type: obj.admin_type,
			active_title: obj.active_title,
			business_id: obj.business_id,
			page: obj.page,
			limit: obj.limit,
		}
	})
}

function goodsList(obj) { //设置活动列表
	return request({
		url: "Promotion/goodsList",
		params: {
			token: obj.token,
			search: obj.search,
			admin_type: obj.admin_type,
			business_id: obj.business_id,
			page: obj.page,
			limit: obj.limit,
			goods_ids: obj.goods_ids
		}
	})
}

function activeGoodsList(obj) { //活动商品列表
	return request({
		url: "Promotion/activeGoodsList",
		params: {
			token: obj.token,
			active_id: obj.active_id,
			admin_type: obj.admin_type,
			type: obj.type,
			page: obj.page,
			limit: obj.limit,
			goods_ids: obj.goods_ids
		}
	})
}

function setStoreGoods(obj) { //添加活动商品列表
	return request({
		url: "Promotion/setStoreGoods",
		params: {
			token: obj.token,
			active_goods_id: obj.active_goods_id,
			data: obj.data,
			kill_price: obj.kill_price,
			kill_number: obj.kill_number,
			limited_number: obj.limited_number,
			sort: obj.sort,
			business_id: obj.business_id,
			token: obj.token,
			admin_type: obj.admin_type,


		}
	})
}


function deleteActiveGoods(obj) { //活动商品删除
	return request({
		url: "Promotion/deleteActiveGoods",
		params: {
			token: obj.token,
			active_goods_id: obj.active_goods_id,
		}
	})
}
function deleteGroupActive(obj) { //拼团商品删除
	return request({
		url: "Promotion/deleteGroupActive",
		params: {
			token: obj.token,
			group_active_id: obj.group_active_id,
		}
	})
}

function couponType(obj) { //优惠券类型下拉列表
	return request({
		url: "Promotion/couponType",

	})
}

function addCouponInfo(obj) { //优惠券添加
	return request({
		url: "Promotion/addCouponInfo",
		params: {
			token: obj.token,
			admin_type: obj.admin_type,
			goods_type: obj.goods_type,
			type: obj.type,
			coupon_name: obj.coupon_name,
			business_id: obj.business_id,
			store_id: obj.store_id,
			number: obj.number,
			discount_price: obj.discount_price,
			use_limits: obj.use_limits,
			limits_price: obj.limits_price,
			type_time: obj.type_time,
			start_time: obj.start_time,
			end_time: obj.end_time,
			effective_days: obj.effective_days,
			goods_data: obj.goods_data,
			remark: obj.remark
		}
	})
}

function couponShop(obj) { //优惠券商品添加列表
	return request({
		url: "Promotion/couponShop",
		type: 'POST',
		params: {
			token: obj.token,
			coupon_id: obj.coupon_id,
			admin_type: obj.admin_type,
			goods_type: obj.goods_type,
			search: obj.search,
			storeid: obj.storeid,
			business_id: obj.business_id,
		}
	})
}

function couponList(obj) { //优惠券列表
	return request({
		url: "Promotion/couponList",
		params: {
			token: obj.token,
			admin_type: obj.admin_type,
			coupon_name: obj.coupon_name,
			type: obj.type,
			store_id: obj.store_id,
			business_id: obj.business_id,
			page: obj.page,
			limit: obj.limit,
		}
	})
}

function couponShopList(obj) { //优惠券商品列表
	return request({
		url: "Promotion/couponShopList",
		params: {
			token: obj.token,
			admin_type: obj.admin_type,

			coupon_id: obj.coupon_id,
			page: obj.page,
			limit: obj.limit,
		}
	})
}

function deleteCouponGoods(obj) { //优惠券商品删除
	return request({
		url: "Promotion/deleteCouponGoods",
		params: {
			token: obj.token,
			coupon_goods_id: obj.coupon_goods_id
		}
	})
}

function updateCoupon(obj) { //优惠券编辑
	return request({
		url: "Promotion/updateCoupon",
		params: {
			token: obj.token,
			admin_type: obj.admin_type,
			goods_data: obj.goods_data,
			coupon_id: obj.coupon_id,
		}
	})
}



function deleteCoupon(obj) { //优惠券商品删除
	return request({
		url: "Promotion/deleteCoupon",
		params: {
			token: obj.token,
			coupon_id: obj.coupon_id,
		}
	})
}

function advertPosition(obj) { //广告位置列表接口
	return request({
		url: "Operate/advertPosition",
	})
}


function addAdvert(obj) { //广告添加
	return request({
		url: "Operate/addAdvert",
		params: {
			token: obj.token, //	是	string	token
			advert_name: obj.advert_name, //	是	string	广告名称
			// advert_position: obj.advert_position, //	是	string	广告位置
			// start_time: obj.start_time, //	是	string	开始时间例2021- 01 - 01
			// end_time: obj.end_time, //	是	string	结束时间
			// is_online: obj.is_online, //	是	int	1上线2不上线
			advert_img: obj.advert_img, //	是	string	广告图片
			// advert_link: obj.advert_link, //	是	string	广告链接
			// advert_remark: obj.advert_remark, //	是	string	广告备注
			admin_type: obj.admin_type, //	是	int	1、超级管理员 2、普通管理员（超级管理员不受权限控制）3、商家管理员 登录后获取 需选择门店后传
			store_id: obj.store_id, //	是	int	门店id
		}
	})
}

function updateAdvert(obj) { //广告编辑
	return request({
		url: "Operate/updateAdvert",
		params: {
			token: obj.token, //	是	string	token
			advert_name: obj.advert_name, //	是	string	广告名称
			advert_position: obj.advert_position, //	是	string	广告位置
			start_time: obj.start_time, //	是	string	开始时间例2021- 01 - 01
			end_time: obj.end_time, //	是	string	结束时间
			is_online: obj.is_online, //	是	int	1上线2不上线
			advert_img: obj.advert_img, //	是	string	广告图片
			advert_link: obj.advert_link, //	是	string	广告链接
			advert_remark: obj.advert_remark, //	是	string	广告备注
			admin_type: obj.admin_type, //	是	int	1、超级管理员 2、普通管理员（超级管理员不受权限控制）3、商家管理员 登录后获取 需选择门店后传
			store_id: obj.store_id, //	是	int	门店id
			advert_id: obj.advert_id
		}
	})
}

function advertList(obj) { //广告列表
	return request({
		url: "Operate/advertList",
		params: {
			token: obj.token, //	是	string	token
			admin_type: obj.admin_type, //	是	int	1、超级管理员 2、普通管理员（超级管理员不受权限控制）3、商家管理员 登录后获取 需选择门店后传
			advert_name: obj.advert_name, //	是	string	广告名称
			// advert_position: obj.advert_position, //	是	string	广告位置
			// end_time: obj.end_time, //	是	string	结束时间
			store_id: obj.store_id, //	是	int	门店id
			limit: obj.limit, //	是	int	一页显示几条数据默认10
			page: obj.page, //	是	int	第几页默认1
		}
	})
}

function groupGoodsList(obj) { //广告列表
	return request({
		url: "/Promotion/groupGoodsList",
		params: {
			store_id: obj.store_id, //	是	int	门店id
		}
	})
}


function deleteAdvert(obj) { //广告删除
	return request({
		url: "Operate/deleteAdvert",
		params: {
			token: obj.token, //	是	string	token
			advert_id: obj.advert_id

		}
	})
}



function setPlatformInfo(obj) { //平台信息
	return request({
		url: "Install/setPlatformInfo",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			platform_id: obj.platform_id,
			platform_name: obj.platform_name, //平台名称
			platform_title: obj.platform_title, //平台标题
			platform_desc: obj.platform_desc, //	平台描述
			key_word: obj.key_word, //	关键词
			platform_logo: obj.platform_logo, //平台LOGO
			hot_line: obj.hot_line, //	客服热线
			service_email: obj.service_email, //客服邮箱
			sort: obj.sort, //	排序
			api_link: obj.api_link, //	接口obj
			store_id: obj.store_id, //	门店id
		}
	})
}


function setLogistics(obj) { //物流公司添加
	return request({
		url: "Install/setLogistics",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			store_id: obj.store_id,
			logistics_id: obj.logistics_id,
			company_name: obj.company_name,
			send_describe: obj.send_describe,
			is_open: obj.is_open,
		}
	})
}

function logisticsList(obj) { //物流公司列表
	return request({
		url: "Install/logisticsList",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			store_id: obj.store_id,
			company_name: obj.company_name,
			send_describe: obj.send_describe,
			is_open: obj.is_open,
			limit: obj.limit,
			page: obj.page,
		}
	})
}

function deleteLogistics(obj) { //删除物流公司
	return request({
		url: "Install/deleteLogistics",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			logistics_id: obj.logistics_id,
		}
	})
}

function regionSel(obj) { //运费模板的省市区接口
	return request({
		url: "Install/regionSel",
		params: {
			token: obj.token, //登陆进来的tooken
			region_id: obj.region_id, //省市区id
		}
	})
}

function setFreight(obj) { //运费模板添加接口
	return request({
		url: "Install/setFreight",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			store_id: obj.store_id, //是	int	门店id 超管传，商户传
			freight_id: obj.freight_id, //非	int	传id走编辑
			template_name: obj.template_name, //是	string	模板名称
			pricing_method: obj.pricing_method, //是	string	计价方式
			number: obj.number, //是	int	数量
			start_price: obj.start_price, //是	int	起步价
			increase_quantity: obj.increase_quantity, //是	int	增加数量
			increase_price: obj.increase_price, //是	int	加价
			province: obj.province, //是	int	省
			city: obj.city, //是	int	市
			county: obj.county, //是	int	县区
			is_open: obj.is_open, //非	string	是否禁用0启用1未启用
		}
	})
}

function freightList(obj) { //运费模板列表
	return request({
		url: "Install/freightList",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			region_id: obj.region_id, //省市区id
			store_id: obj.store_id,
			limit: obj.limit,
			page: obj.page,
		}
	})
}
function storePrintList(obj) { //运费模板列表
	return request({
		url: "StorePrint/storePrintList",
		params: {
			token: obj.token, //登陆进来的tooken
			login_type: obj.login_type, //身份
			storeid: obj.storeid,
			limit: obj.limit,
			page: obj.page,
		}
	})
}
function addStorePrint(obj) { //运费模板列表
	return request({
		url: "StorePrint/addStorePrint",
		params: {
			token: obj.token, //登陆进来的tooken
			login_type: obj.login_type, //身份
			storeid: obj.storeid,
			device_no: obj.device_no,
			device_appid: obj.device_appid,
			device_appkey: obj.device_appkey,
			print_url: obj.print_url,
		}
	})
}
function storePrintUp(obj) { //运费模板列表
	return request({
		url: "StorePrint/storePrintUp",
		params: {
			token: obj.token, //登陆进来的tooken
			login_type: obj.login_type, //身份
			storeid: obj.storeid,
			device_no: obj.device_no,
			device_appid: obj.device_appid,
			device_appkey: obj.device_appkey,
			print_url: obj.print_url,
			spid: obj.spid,
			status: obj.status
		}
	})
}

function deletefreight(obj) { //删除物流模板
	return request({
		url: "Install/deletefreight",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			freight_id: obj.freight_id,
		}
	})
}

function systemInfo(obj) { //系统设置修改
	return request({
		url: "Install/systemInfo",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			business_id: obj.business_id,	//是	int	门店id 超管传
		}
	})
}

function groupActiveList(obj) { //系统设置修改
	return request({
		url: "Promotion/groupActiveList",
		params: {
			token: obj.token, //登陆进来的tooken
			login_type: obj.login_type, //身份
			status: 4
			// business_id: obj.business_id,	//是	int	门店id 超管传
		}
	})
}
function setGroupActive(obj) { //系统设置列表
	return request({
		url: "Promotion/setGroupActive",
		params: {
			token: obj.token, //登陆进来的tooken
			login_type: obj.login_type, //身份
			group_active_id: obj.group_active_id,
			store_id: obj.store_id,//
			goods_id: obj.goods_id,	//是	int	门店id 超管传
			goods_name: obj.goods_name,	//非	string	传id走编辑
			start_time: obj.start_time,	//是	string	小程序APPID
			end_time: obj.end_time,	//是	string	小程序APPsecret
			is_on_sale: obj.is_on_sale,//是	string	小程序商户号
			group_number: obj.group_number,	//是	string	支付密钥
			group_person_number: obj.group_person_number,	//是	int	设备号
			group_price: obj.group_price,
			original_price: obj.original_price
		}
	})
}

function setSystem(obj) { //系统设置列表
	return request({
		url: "Install/setSystem",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			business_id: obj.business_id,
			system_id: obj.system_id,//
			business_id: obj.business_id,	//是	int	门店id 超管传
			system_id: obj.system_id,	//非	string	传id走编辑
			mini_app_id: obj.mini_app_id,	//是	string	小程序APPID
			mini_app_secret: obj.mini_app_secret,	//是	string	小程序APPsecret
			mini_merchant: obj.mini_merchant,//是	string	小程序商户号
			paykey: obj.paykey,	//是	string	支付密钥
			termNo: obj.termNo,	//是	int	设备号
		}
	})
}


function setSelfTake(obj) { //添加自提点
	return request({
		url: "Install/setSelfTake",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			business_id: obj.business_id,	//是	int	门店id 超管没有权限添加
			id: obj.id,	//	非	string	传id走编辑
			name: obj.name,	//是	string	自提点名称
			province: obj.province,	//	是	string	省
			city: obj.city,	//	是	string	市
			county: obj.county,	//	是	string	区
			address: obj.address,	//	是	int	详细地址
			mobile: obj.mobile,	//	是	int	联系方式
		}
	})
}

function selfTakeList(obj) { //自提点列表
	return request({
		url: "Install/selfTakeList",
		params: {
			token: obj.token, //登陆进来的tooken
			admin_type: obj.admin_type, //身份
			business_id: obj.business_id,	//是	int	门店id 超管没有权限添加
			page: obj.page,
			limit: obj.limit,
		}
	})
}
function addStoreNumber(obj) { //店铺删除
	return request({
		url: "Store/addStoreNumber",
		params: {
			token: obj.token,
			storeid: obj.id
		}
	})
}
function orderInfo(obj) { //店铺删除
	return request({
		url: "Shorder/orderInfo",
		params: {
			token: obj.token,
			order_sn: obj.order_sn
		}
	})
}
function confirmDeliverGoods(obj) { //店铺删除
	return request({
		url: "Shorder/confirmDeliverGoods",
		params: {
			token: obj.token,
			order_sn: obj.order_sn,
			express_sn: obj.express_sn,
			express_name: obj.express_name
		}
	})
}
function selOrderExpressList(obj) { //查询快递发货列表
	return request({
		url: "Express/selOrderExpressList",
		params: {
			token: obj.token,
			storeid: obj.storeid,
			page: obj.page,
			limit: obj.limit,
			login_type: obj.login_type,
			order_sn: obj.order_sn,
		}
	})
}
function expressSel(obj) { //查询快递
	return request({
		url: "Express/expressSel",
		params: {
			token: obj.token,
			order_sn: obj.order_sn,
		}
	})
}
function getNumGoodsClassData(obj) { //数字权益分类列表
	return request({
		url: "NumGoods/getNumGoodsClassData",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid,

		}
	})
}
function upNumGoodsData(obj) { //数字权益商品编辑
	return request({
		url: "NumGoods/upNumGoodsData",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid,
			typeid: obj.typeid,
			type: obj.type,
			ncid: obj.ncid,
			class_name: obj.class_name,
			ng_name: obj.ng_name,
			title: obj.title,
			selling_price: obj.selling_price,
			original_price: obj.original_price,
			stock: obj.stock,
			is_show: obj.is_show,
			services: obj.services,
			img: obj.img,
			nid: obj.nid,
			n_status: obj.n_status,
			ng_code: obj.ng_code,

		}
	})
}
function numGoodsAddData(obj) { //数字权益商品添加
	return request({
		url: "NumGoods/numGoodsAddData",
		params: {
			token: obj.token,
			login_type: obj.login_type,
			storeid: obj.storeid,
			typeid: obj.typeid,
			type: obj.type,
			ncid: obj.ncid,
			class_name: obj.class_name,
			ng_name: obj.ng_name,
			title: obj.title,
			selling_price: obj.selling_price,
			original_price: obj.original_price,
			stock: obj.stock,
			is_show: obj.is_show,
			services: obj.services,
			img: obj.img,
			n_status: obj.n_status,
			ng_code: obj.ng_code,
		}
	})
}
function numGoodsQueryList(obj) { //数字权益商品添加
	return request({
		url: "NumGoods/numGoodsQueryList",
		params: {
			token: obj.token,
			ng_name: obj.ng_name,
			storeid: obj.storeid,
			page: obj.page,
			limit: obj.limit,
			is_grounding: obj.is_grounding,
		}
	})
}
function setNumGoodsShow(obj) { //数字权益商品上下架修改
	return request({
		url: "NumGoods/setNumGoodsShow",
		params: {
			token: obj.token,
			is_show: obj.is_show,
			nid: obj.nid,
		}
	})
}
function numGoodsGrounding(obj) { //数字权益商品审核
	return request({
		url: "NumGoods/numGoodsGrounding",
		params: {
			token: obj.token,
			nid: obj.nid,
			is_grounding: obj.is_grounding,//is_grounding2审核通过，3审核不通过
		}
	})
}
function delNumGoodsData(obj) { //数字权益商品删除
	return request({
		url: "NumGoods/delNumGoodsData",
		params: {
			token: obj.token,
			nid: obj.nid,
		}
	})
}
function selNumGoodsInfo(obj) { //数字权益商品编辑查询
	return request({
		url: "NumGoods/selNumGoodsInfo",
		params: {
			token: obj.token,
			nid: obj.nid,
		}
	})
}
function selNumOrderList(obj) { //数字权益订单列表
	return request({
		url: "NumGoods/selNumOrderList",
		params: {
			token: obj.token,
			nid: obj.nid,
			is_status: obj.is_status,
			ng_name: obj.ng_name,
			p_storeid: obj.p_storeid,
			storeid: obj.storeid,
			norder_sn: obj.norder_sn,
			page: obj.page,
			limit: obj.limit,
		}
	})
}
function selNumOrderInfo(obj) { //数字权益订单详情
	return request({
		url: "NumGoods/selNumOrderInfo",
		params: {
			token: obj.token,
			norder_id: obj.norder_id,
		}
	})
}

//对字符串进行加密   
function compileStr(code) {
	var c = String.fromCharCode(code.charCodeAt(0) + code.length);
	for (var i = 1; i < code.length; i++) {
		c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1));
	}
	return escape(c);
}
//字符串进行解密   
function uncompileStr(code) {
	code = unescape(code);
	var c = String.fromCharCode(code.charCodeAt(0) - code.length);
	for (var i = 1; i < code.length; i++) {
		c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1));
	}
	return c;
}
//获取url参数
function getUrl(name) {
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	var r = window.location.search.substr(1).match(reg);
	if (r != null) return decodeURI(r[2]);
	return null;
}
//全局定时任务
function orderTime(obj) {
	const timer = setInterval(function get() {
		clearInterval(timer);
		alert('已经十五分钟了');
	}, obj);
}
//链接
function readimgUrl() { //图片上传路径
	// return '/apis'    //本地

	return 'https://multimerchant.yinheyun.com.cn/' //线上
}
function imgUrl() { //图片上传路径
	// return '/apis/admin.php/'    //本地
	return 'https://multimerchant.yinheyun.com.cn/admin.php/' //线上
}
function imgUrl2() { //图片下载路径
	// return '/apis'    //本地
	return 'https://multimerchant.yinheyun.com.cn/'  //线上
}


export default {
	subbranchSelData,

	deletePop,
	setPop,
	popList,
	deleteGroupActive,
	selNumOrderInfo,
	selNumOrderList,
	setNumGoodsShow,
	upNumGoodsData,
	selNumGoodsInfo,
	delNumGoodsData,
	numGoodsGrounding,
	numGoodsQueryList,
	numGoodsAddData,
	getNumGoodsClassData,
	closeOrder,
	expressSel,
	selOrderExpressList,
	groupActiveList,
	setGroupActive,
	groupGoodsList,
	addStorePrint,
	storePrintUp,
	storePrintList,
	IndexDataOverview,
	confirmDeliverGoods,
	readimgUrl,
	selfTakeList,
	orderInfo,
	setSelfTake,
	setSystem,
	systemInfo,
	deleteLogistics,
	logisticsList,
	setLogistics,
	deleteAdvert,
	updateAdvert,
	setPlatformInfo,
	advertList,
	addAdvert,
	advertPosition,
	deleteCouponGoods,
	updateCoupon,
	deleteCoupon,
	couponList,
	addCouponInfo,
	couponShop,
	couponShopList,
	couponType,
	deleteActiveGoods,
	setStoreGoods,
	activeGoodsList,
	goodsList,
	changShelf,
	delActive,
	businessStoreList,
	businessList,
	addActive,
	activeList,
	deletefreight,
	freightList,
	setFreight,
	regionSel,
	Login,
	Index,
	roleList,
	roleDel,
	roleUpIsStart,
	roleAddName,
	roleUpInfo,
	roleAddList,
	roleAdd,
	adminSel,
	selRoleData,
	addAdmin,
	memberLabelDel,
	updateAdmin,
	updateAdminSel,
	adminUpIsStart,
	delAdmin,
	selIndustryData,
	delIndustry,
	selCategoryData,
	brandList,
	storeSelectData,
	addStoreData,
	storeList,
	logout,
	delStoreData,
	addIndustry,
	upIndustry,
	selIndustryList,
	addCategory,
	delCategory,
	upCategory,
	upIsShow,
	brandDel,
	brandAdd,
	brandOneData,
	brandUp,
	storeOneData,
	getMerchantData,
	upStoreData,
	goodsQueryList,
	selGoodsStoreName,
	delGoodsData,
	goodsIsGrounding,
	addTypeList,
	typeAddData,
	typeListData,
	delTypeData,
	typeOneData,
	upTypeData,
	upLevelStatus,
	selGoodsClassData,
	goodsClassAdd,
	goodsClassUp,
	delGoodsClassData,
	getNextSelectList,
	selGoodsSpecList,
	addGoodsSpec,
	delGoodsSpecData,
	selGoodsSpecInfo,
	upGoodsSpecData,
	labelAddData,
	labelListData,
	delLabelData,
	upLabelData,
	meteringAddData,
	meteringListData,
	upmeteringType,
	delmeteringData,
	upmeteringData,
	selGoodsTypeData,
	selGoodsSpecData,
	GoodsselGoodsClassData,
	selGoodsReviewData,
	goodsAddData,
	selGoodsRecycleData,
	upGoodsReviewData,
	upGoodsRecycleData,
	setGoodsReviewType,
	selGoodsReviewDataList,
	goodsUpInfoData,
	selOrderList,
	goodsUpData,
	receivingList,
	receivingSet,
	orderSetList,
	orderSetUp,
	refundOrderList,
	refundOrderInfo,
	getStoreDataList,
	refundOrderSet,
	orderRefundGoodsSel,
	orderRefundGoodsSet,
	orderRefundGoodsDel,
	imgUrl2,
	getUrl,
	imgUrl,
	memberLabelList,
	memberLabelAdd,
	memberGradeList,
	memberGradeAdd,
	memberLabelUp,
	memberGradeDel,
	memberGradeCheckdeUp,
	memberList,
	memberInfo,
	memberDel,
	goodsStockList,
	distributionSel,
	distributionSet,
	disGradeSel,
	disGradeSet,
	getUpStoreData,
	memberGradeUp,
	selRoleList,
	addStoreNumber,
}
