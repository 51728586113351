import Vue from 'vue'
import Vuex from 'vuex'
import * as types from "./modules/mutations-type.js"
import moduleA from "./modules/moduleA.js"
import mutations from "./modules/mutations.js"
import actions from "./modules/actions"
import getters from "./modules/getters"
Vue.use(Vuex)
// 总结:
// 模块内的mutaetions,actions,getters都是直接交给store,而模块内的状态需要通过store.state.模块名.状态  获取

export default new Vuex.Store({
  state: {
	  grilFl: null,
	  ///////////////////////////////////////////*****************************/////////////////////////////////////
	  goodsAmend: null
  },
  mutations,
  actions,
  getters,
  modules: {
	  moduleA:moduleA,
  }
})

